import {
  Create,
  FileField,
  FileInput,
  Loading,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect
} from 'react-admin';
import { AdminHardwareResponseDto } from 'shared/api';
import { API_BASE_URL_ADMIN } from 'shared/config';
import { API_URL } from 'shared/constants/api-url';

import { LABELS } from 'shared/constants/labels';
import { useHttpClient } from 'shared/hooks/useHttpClient';
import { httpClient } from 'shared/services/http-client';

import { isRequired } from 'shared/validators';
import { uploadFile } from '../../../utils/upload-files';

export const FirmwareCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();

  const { data, isLoading } = useHttpClient<AdminHardwareResponseDto>(API_URL.getHardwares);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) return null;

  const hardwareVersionOptions = data.data.map((hardware: any) => ({
    id: hardware.id,
    version: `${hardware.model.name}, ${hardware.version}`,
  }));

  const handleSubmit = async (data: any) => {
    try {
      if (data.downloadFileKey.rawFile) {
        const uploadedFile = await uploadFile(data.downloadFileKey?.rawFile, data.hardwareId, data.firmwareVersion);

        if (uploadedFile.fileKey) {
          try {
            await httpClient(`${API_BASE_URL_ADMIN}${API_URL.createFirmware}`, {
              method: 'POST',
              body: JSON.stringify({
                hardwareId: data.hardwareId,
                version: data.firmwareVersion,
                downloadFileKey: uploadedFile.fileKey,
              }),
            });

            notify('Прошивка добавлена', { type: 'success' });
            redirect('/firmwares');
          } catch (error: any) {
            notify(error.message, { type: 'error' });
          }
        }
      }
    } catch (err: any) {
      notify(err.message, { type: 'error' });
    }
  };

  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit}>
        <SelectInput
          source="hardwareId"
          validate={[isRequired]}
          label={LABELS.hardwareVersion}
          inputProps={{ maxLength: 127 }}
          helperText="Уникальное, не более 127 знаков"
          choices={hardwareVersionOptions}
          optionText="version"
          fullWidth
        />
        <TextInput
          source="firmwareVersion"
          validate={[isRequired]}
          label={LABELS.version}
          inputProps={{ maxLength: 127 }}
          helperText="Уникальное, не более 127 знаков"
        />
        <FileInput
          label={LABELS.downloadFileKey}
          source="downloadFileKey"
          accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
          validate={[isRequired]}
        >
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
