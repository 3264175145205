import {FC, useState} from 'react';
import { Tab, TabbedShowLayout, TextField, UrlField, DateField, useNotify, Button, DeleteButton } from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { AdminFirmwareResponseDto } from 'shared/api';
import { replaceQueryParams } from '../../../../utils/replace-query-params';
import { API_BASE_URL_ADMIN, APP_BASE_URL } from '../../../../config';
import DownloadIcon from '@mui/icons-material/GetApp';
import {CircularProgress} from "@mui/material";

interface Props {
  data?: AdminFirmwareResponseDto;
}

export const FirmwareShowContent: FC<Props> = ({ data }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    if (data?.id) {
      setLoading(true);
      try {
        const url = new URL(`${API_BASE_URL_ADMIN}/firmwares/${data.id}/download`);
        url.search = replaceQueryParams(url.search); // Используем вашу функцию для замены query

        const token = localStorage.getItem('token');

        const response = await fetch(url.toString(), {
          method: 'GET',
          headers: { Authorization: `Bearer ${token}` },
        });

        const disposition = response.headers.get('Content-Disposition');
        let fileName = 'firmware.zip';

        if (disposition) {
          const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = regex.exec(disposition);

          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }

        // Получаем бинарные данные через blob()
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        notify(`Начато скачивание файла прошивки ${fileName}`, { type: 'success' });
      } catch (error: any) {
        console.error('Произошла ошибка при скачивании:', error);
        notify(
          `Что-то пошло не так: ${error?.message}
        `,
          { type: 'error' },
        );
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <TabbedShowLayout>
      <Tab label="Основная информация">
        <TextField source="id" label={LABELS.id} />
        <TextField source="version" label={LABELS.version} />
        <div>
          <div style={{ fontFamily: 'sans-serif', fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)', marginBottom: '3px' }}>
            Hardware
          </div>
          <a href={`${APP_BASE_URL}/hardwares/${data?.hardware.id}/show`}>
            <TextField source="hardware.model.name" label={LABELS.modelName} />
            {' '}
            <TextField source="hardware.version" label={LABELS.hardware} />
          </a>
        </div>
        <Button
          onClick={handleDownload}
          label={LABELS.downloadFirmwareButton}
          variant="contained"
          style={{ marginTop: '10px' }}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : <DownloadIcon />} // Отображаем спиннер во время загрузки
        />
        <DateField source="createdAt" label={LABELS.addedAt} />

        {data?.deletedAt && <DateField source="deletedAt" label={LABELS.deletedAt} />}
      </Tab>
    </TabbedShowLayout>
  );
};
