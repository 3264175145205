/* eslint-disable react/jsx-key */
import {
  Datagrid,
  DateField,
  Filter,
  List,
  SelectInput,
  ShowButton,
  TextField,
  TextInput
} from 'react-admin';
import { LABELS } from 'shared/constants/labels';
import { UserListActions } from './ui/actions';

const UsersFilters = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput source="id" alwaysOn />
      <TextInput source="username" alwaysOn />
      <TextInput source="phone" alwaysOn />
      <SelectInput
        source="withDevices"
        alwaysOn
        choices={[
          { id: 'true', name: 'yes' },
          { id: 'false', name: 'no' },
        ]}
        defaultValue=""
      />
    </Filter>
  );
};

export const UserList = () => {
  return (
    <List actions={<UserListActions />} filters={<UsersFilters />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" label={LABELS.userId} sortable={false} />
        <TextField source="username" label={LABELS.username} sortable={true} />
        <TextField source="phone" label={LABELS.phone} sortable={false} />
        <DateField source="dateOfBirth" label={LABELS.dateOfBirth} sortable={false} />
        <TextField source="role" label={LABELS.userRole} sortable={false} />
        <DateField source="createdAt" label={LABELS.registratedAt} sortable={true} />
        <DateField source="deletedAt" label={LABELS.deletedAt} sortable={true} />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
